import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token, ruolo) {
        if(!ruolo) ruolo = '';
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_all?token="+token+"&ruolo="+ruolo).then(response => {
            return response.data;
        });
    },
    async getAllPartner(token, appartenenza) {
        if(!appartenenza) appartenenza = '';
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_all_partner?token="+token+"&appartenenza="+appartenenza).then(response => {
            return response.data;
        });
    },
    async getAllClientPartner(token, appartenenza) {
        if(!appartenenza) appartenenza = '';
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_all_clienti_partner?token="+token+"&appartenenza="+appartenenza).then(response => {
            return response.data;
        });
    },
    async getUtenteFromId(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async getUtenteFromToken(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_one?token="+token).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async updateMine(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/update_mine?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
}