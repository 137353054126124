import axios from 'axios';
import config from '@/config/config';

export const generalFunction = {
    setCookie: function (cname, cvalue, exdays){
        if(this.getCookie(cname)){
            document.cookie = cname + "=" +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + " ;path=/";
    },
    getCookie: function (cname) {
        const name = cname + "=";
        const values = document.cookie.split(';');
        for(let value of values) {
            while (value.charAt(0) === ' ') value = value.substring(1);
            if (value.indexOf(name) === 0) return value.substring(name.length, value.length);
        }
        return "";
    },
    getToken: async function () {
        return await this.getCookie('token');
    },
    checkToken: async function () {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/check_token?token="+this.getToken()).then(response => {
            return response.data;
        });
    },
    getIdStabilimentoFromQR: async function (matricola) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/get_id_from_matricola?matricola="+matricola).then(response => {
            return response.data;
        });
    },
    getRuolo: async function (token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/utenti/get_ruolo?token="+token).then(response => {
            return response.data;
        });
    },
    async getItemCart(country, billingAddress, lang) {
        let indirizzo = {};
        if (
            country.continent &&
            country.country
        ) {
            indirizzo.continente_spedizione = country.continent;
            indirizzo.nazione_spedizione = country.country;
        }

        let indirizzo_fatturazione = {};
        if (
            billingAddress &&
            billingAddress.tipo &&
            billingAddress.indirizzo &&
            billingAddress.indirizzo.continente_fatturazione &&
            billingAddress.indirizzo.nazione_fatturazione
        ) {
            indirizzo_fatturazione.tipo = billingAddress.tipo;
            indirizzo_fatturazione.continente_fatturazione =  billingAddress.indirizzo.continente_fatturazione;
            indirizzo_fatturazione.nazione_fatturazione = billingAddress.indirizzo.nazione_fatturazione;
        }

        let listProducts = [];
        let token = await this.getToken();
        let data = {
            lang: lang,
            token: token,
            id_canale: process.env.VUE_APP_ID_CANALE,
            indirizzo: indirizzo,
            indirizzo_fatturazione: indirizzo_fatturazione,
            codice: this.getCookie('codice_bonus')
        };

        if (!token && localStorage.getItem('cart')) {
            let cart = JSON.parse(localStorage.getItem('cart'));
            if (cart.length > 0) data = Object.assign(data, { products: cart });
        }
        listProducts = await apiUser.getUserProducts(token, data);
        return listProducts;
    },
    async getItemOrder(id, items, tokenAuth) {
        let token = await this.getToken();
        let user = await apiUser.getUser(token);
        let listProducts = [];
        if (
            (
                token &&
                user &&
                user._id
            ) || tokenAuth
        ) {
            let data = {
                id_ordine: id,
                token: tokenAuth
            };
            listProducts = await apiOrder.getProductsInOrder(token, data);
            /*let productsInOrder = await apiOrder.getProductsInOrder(token, data);
            if (items) {
                for (let product of items)
                    for (let realProduct of productsInOrder)
                        if (String(realProduct.gruppo_variazioni.variazioni._id) === String(product.id_variazione))
                            listProducts.push(await this.controlItemProduct(product, realProduct, lang));
            }*/
        }
        return listProducts;
    },
    controlItemProduct(product, realProduct, lang) {
        product.name_product = 'Senza nome';
        if (realProduct[lang] && realProduct[lang].nome)
            product.name_product = realProduct[lang].nome;

        product.name_group = 'Senza nome';
        if (
            realProduct.gruppo_variazioni &&
            realProduct.gruppo_variazioni[lang] &&
            realProduct.gruppo_variazioni[lang].nome
        ) product.name_group = realProduct.gruppo_variazioni[lang].nome;

        product.name_variation = 'Senza nome';
        if (
            realProduct.gruppo_variazioni &&
            realProduct.gruppo_variazioni.variazioni &&
            realProduct.gruppo_variazioni.variazioni[lang] &&
            realProduct.gruppo_variazioni.variazioni[lang].nome
        ) product.name_variation = realProduct.gruppo_variazioni.variazioni[lang].nome;

        product.image_product = '../../../../assets/images/p1-col.jpg';
        if (
            realProduct.gruppo_variazioni &&
            realProduct.gruppo_variazioni.images &&
            realProduct.gruppo_variazioni.images[0]
        ) product.image_product = process.env.VUE_APP_DOMAIN_ADMIN+'/admin/components/specifiche/OfficineMattio/images/'+realProduct.gruppo_variazioni.images[0].dir+"/"+realProduct.gruppo_variazioni.images[0].value;

        return product;
    },
    async uploadImage(file, diectory, filename) {
        let formData = new FormData();
        formData.append('file', file, filename);
        return await axios.post(process.env.VUE_APP_DOMAIN_ADMIN + "/admin/components/general/upload.php?link="+ diectory +"&force", formData).then(response => {
            return response.data;
        });
    },
    async getImage(params) {
        return await axios.get(process.env.VUE_APP_DOMAIN_ADMIN + "/admin/components/media_library/get_image_dimensions.php?force" +
            "&dimensioni=" + params.dimensioni +
            "&dir=" + params.dir +
            "&value=" + params.value
        ).then(response => {
            return response.data;
        });
    },
    async getContinent(params) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_continent?search=code,"+params).then(response => {
            return response.data;
        });
    },
    async getContinents() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_continents").then(response => {
            return response.data;
        });
    },
    async getWorlds() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/gets_country_world?sort=name").then(response => {
            return response.data;
        });
    },
    async getCountries() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_countries_world").then(response => {
            return response.data;
        });
    },
    async getCountry(params) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_country_client?code="+params).then(response => {
            return response.data;
        });
    },
    async getGeoIpCountry() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_geoip_country").then(response => {
            return response.data;
        });
    },
    async controlUserCountry() {
        let controlCountry, continent, country, lang, currency = undefined;
        if (
            this.getCookie('continent') &&
            this.getCookie('continent') !== 'undefined' &&
            this.getCookie('country') &&
            this.getCookie('country') !== 'undefined'
        ) {
            let controlContinent = await this.getContinent(this.getCookie('continent'));
            if (controlContinent) continent = this.getCookie('continent');
            else this.setCookie('continent', '', 0);

            controlCountry = await this.getCountry(this.getCookie('country'));
            if (controlCountry) country = this.getCookie('country');
            else this.setCookie('country', '', 0);

            currency = await this.controlCountryCurrency(controlCountry);
        } else {
            let token = await this.getToken();
            let user = await apiUser.getUser(token);
            if (
                user &&
                user.indirizzi_spedizione &&
                user.indirizzi_spedizione.length > 0
            ) {
                let countryContinent = undefined;
                let countryAddress = undefined;
                let indirizzoPreferito = user.indirizzi_spedizione.find(obj => {
                    return obj.preferito;
                });
                if (indirizzoPreferito) {
                    countryContinent = indirizzoPreferito.continente_spedizione;
                    countryAddress = indirizzoPreferito.nazione_spedizione;
                } else {
                    countryContinent = user.indirizzi_spedizione[0].continente_spedizione;
                    countryAddress = user.indirizzi_spedizione[0].nazione_spedizione;
                }

                if (!countryContinent) countryContinent = 'EUCEE';
                if (!countryAddress) countryAddress = 'IT';

                let controlContinent = await this.getContinent(countryContinent);
                if (controlContinent) continent = countryContinent;

                controlCountry = await this.getCountry(countryAddress);
                if (controlCountry) country = countryAddress;

                this.setCookie('continent', countryContinent, 365);
                this.setCookie('country', countryAddress, 365);

                currency = await this.controlCountryCurrency(controlCountry);
            } else {
                let countryGeoIp = await this.getGeoIpCountry();
                if (
                    countryGeoIp &&
                    countryGeoIp.continente &&
                    countryGeoIp.nazione
                ) {
                    controlCountry = countryGeoIp;
                    continent = countryGeoIp.continente;
                    country = countryGeoIp.nazione;
                    this.setCookie('continent', countryGeoIp.continente, 365);
                    this.setCookie('country', countryGeoIp.nazione, 365);

                    currency = await this.controlCountryCurrency(countryGeoIp);
                }
            }
        }

        if (currency) {
            if (currency.acronimo) this.setCookie('acronimo', currency.acronimo, 365);
            if (currency.formato) this.setCookie('formato', currency.formato, 365);
        }

        if (this.getCookie('lang')){
            let supportedLanguages = await this.getSupportedLanguages();
            if (supportedLanguages.includes(this.getCookie('lang')))
                lang = this.getCookie('lang');
        } else lang = await generalFunction.controlCountryLang(controlCountry);

        localStorage.setItem('websiteLang', JSON.stringify(await this.getSsiteTranslation(lang)));

        if (!lang) lang = 'it';
        this.setCookie('lang', lang, 365);

        if (
            !continent ||
            !country
        ) await this.controlUserCountry();
        else {
            return {
                continent: continent,
                country: country,
                currency: currency,
                lang: lang
            }
        }
    },
    async controltranslation(lang) {
        if (!lang) lang = 'it';
        let translation = {};
        if (localStorage.getItem('websiteLang')) {
            let websiteLang = JSON.parse(localStorage.getItem('websiteLang'));
            if (Object.entries(websiteLang).length !== 0)
                if (websiteLang.lang === lang)
                    translation = websiteLang;
        }

        if (Object.entries(translation).length === 0)
            translation = await this.getSsiteTranslation(lang);

        return translation;
    },
    async getSsiteTranslation(params) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_website?search=lang,"+params).then(response => {
            return response.data;
        });
    },
    async getSsiteTranslations() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_websites").then(response => {
            return response.data;
        });
    },
    async getSupportedLanguages() {
        return ['it','en'];
        /*return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_lingue_supportate").then(response => {
            return response.data;
        });*/
    },
    async controlCountryCurrency(country) {
        let acronimo = 'EUR';
        let formato = 'it-IT';
        if (
            country.valuta &&
            country.valuta[0]
        ) {
            if (country.valuta[0].acronimo)
                acronimo = country.valuta[0].acronimo;

            if (country.valuta[0].formato)
                formato = country.valuta[0].formato;
        }
        return {
            acronimo: acronimo,
            formato: formato
        };
    },
    async controlCountryLang(country) {
        let lang = undefined;
        let supportedLanguages = await this.getSupportedLanguages();
        if (country.languages) {
            for (let countryLang of country.languages) {
                if (
                    !lang &&
                    supportedLanguages.includes(countryLang)
                ) lang = countryLang;
            }
        }
        if (!lang) lang = 'it';
        return lang;
    },
    async getLang() {
        let lang = undefined;
        let supportedLanguages = await this.getSupportedLanguages();
        if (supportedLanguages.includes(this.getCookie('lang')))
            lang = this.getCookie('lang');
        if (!lang) lang = 'it';
        return lang;
    },
    async subscribeNewsletter(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/officinemattio_sendinblue_create_contact", params).then(response => {
            return response.data;
        });
    },
    setTotalCurrency(value, currency) {
        let formato = 'it-IT';
        let acronimo = 'EUR';
        if (
            currency &&
            currency.formato &&
            currency.acronimo
        ) {
            formato = currency.formato;
            acronimo = currency.acronimo;
        }

        let formatter = new Intl.NumberFormat(formato, {
            style: 'currency',
            currencyDisplay: 'code',
            currency: acronimo,
        });

        return formatter.format(value);
    },
    async getPagina(nome) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_pagina_client?search=_id," + nome).then(response => {
            return response.data;
        });
    },
    async controlComponentsPages(components, lang, idPage) {
        let returnComponents = [];
        for (let component of components) {
            if (
                component.pubblicato &&
                !component.archiviato
            ) {
                component.rTitle = undefined;
                component.rText = undefined;
                component.rFixedProducts = [];
                component.rProducts = [];
                component.rProductsLength = 0;
                component.rComponentPage = 1;
                switch (component.tipo) {
                    case "titolo": {
                        if (component.titolo) {
                            if (
                                component.titolo[lang] &&
                                component.titolo[lang].titolo
                            ) component.rTitle = component.titolo[lang].titolo;

                            if (
                                lang !== config.defaultLang &&
                                !component.rTitle &&
                                component.titolo[config.defaultLang] &&
                                component.titolo[config.defaultLang].titolo
                            ) component.rTitle = component.titolo[config.defaultLang].titolo;
                        }
                        break;
                    }
                    case "testo": {
                        if (component.testo) {
                            if (
                                component.testo[lang] &&
                                component.testo[lang].testo
                            ) component.rText = component.testo[lang].testo;

                            if (
                                lang !== config.defaultLang &&
                                !component.rText &&
                                component.testo[config.defaultLang] &&
                                component.testo[config.defaultLang].testo
                            ) component.rText = component.testo[config.defaultLang].testo;
                        }
                        break;
                    }
                    case "prodotti": {
                        let params = { _id: idPage + '-' + component._id }
                        component.rFixedProducts =
                            component.rProducts =
                                await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/get_product_by_rules", params).then(response => {
                                    return response.data;
                                });
                        component.rProductsLength = component.rProducts.length;
                        break;
                    }
                }
                returnComponents.push(component);
            }
        }
        return returnComponents;
    },
    async getPage(params) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/get_pagina_client?search=permalink,"+params).then(response => {
            return response.data;
        });
    },
    async controlBonusCode(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/controlla_codice_bonus", params).then(response => {
            return response.data;
        });
    },
    getExtension(file) {
        if(file) return file.split('.').pop();
        else return false;
    }
};
