import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import { generalFunction } from './js/generalFunction.js';
import { EventBus } from '@/eventBus';
import { store } from "@/store";
import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload);

Vue.use(require('vue-cookies'));
 
Vue.config.productionTip = false;

Vue.prototype.generalFunction = generalFunction;
Vue.prototype.EventBus = EventBus;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
