<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      class="hide_print"
    >
      <div class="d-flex align-center">
        <router-link :to="token ? '/homepage' : '/'">
          <v-img
            alt="LaCelle Logo"
            class="shrink"
            contain
            src="./assets/logo_bianco.png"
            width="120"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>
      
      <v-btn
        to="/login"
        text
        color="white"
      >
        <span class="mr-2">{{ nomeUtente }}</span>
        <v-icon small>fas fa-user</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <Alert></Alert>
  </v-app>
</template>
<style lang="scss">
  @import "./assets/styles/main.scss";
</style>

<script>
import apiUtenti from '@/js/pages/utenti';
const Alert = () => import('@/components/Alert');
import { generalFunction } from './js/generalFunction.js';

export default {
  name: 'App',

  components: {
    Alert
  },
  watch:{
    async $route (to, from){
      this.token = this.$cookies.get('token');
      if((from.name == 'Login' || !this.nomeUtente) && this.token){
        let datiUtente = await apiUtenti.getUtenteFromToken(this.token);
        this.nomeUtente = datiUtente ? datiUtente.nominativo1 : '';
        this.$store.state.token = await generalFunction.getToken();
        this.$store.state.ruolo = await generalFunction.getRuolo(this.$store.state.token);
      }
    }
  },
  data() {
    return {
      token: this.$cookies.get('token'),
      nomeUtente: ''
    }
  },
  async created() {
    if(!this.token) this.token = this.$cookies.get('token');
    if(this.token) {
      let datiUtente = await apiUtenti.getUtenteFromToken(this.token);
      this.nomeUtente = datiUtente ? datiUtente.nominativo1 : '';
      this.$store.state.token = await generalFunction.getToken();
      this.$store.state.ruolo = await generalFunction.getRuolo(this.$store.state.token);
    }
  },
};
</script>
