import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import { generalFunction } from '@/js/generalFunction.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/homepage',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "homepage" */ '@/views/Homepage_Opener.vue'),
    meta: {
      ruolo: ['Master','Cliente','Partner','Tecnico']
    }
  },
  {
    path: '/listUtenti',
    name: 'listUtenti',
    component: () => import(/* webpackChunkName: "listUtenti" */ '@/views/ListUtenti.vue'),
    meta: {
      ruolo: ['Master','Partner','Tecnico']
    }
  },
  {
    path: '/newUtente',
    name: 'newUtente',
    component: () => import(/* webpackChunkName: "newUtente" */ '@/views/NewUtente.vue'),
    meta: {
      ruolo: ['Master','Partner','Tecnico']
    }
  },
  {
    path: '/schedaUtente',
    name: 'schedaUtente',
    component: () => import(/* webpackChunkName: "schedaUtente" */ '@/views/SchedaUtente.vue'),
    meta: {
      need: ['_id'],
      ruolo: ['Master', 'Tecnico', 'Partner']
    }
  },
  {
    path: '/listAttivita',
    name: 'listAttivita',
    component: () => import(/* webpackChunkName: "listAttivita" */ '@/views/ListAttivita.vue'),
    meta: {
      ruolo: ['Master', 'Tecnico', 'Cliente', 'Partner']
    }
  },
  {
    path: '/newAttivita',
    name: 'newAttivita',
    component: () => import(/* webpackChunkName: "newAttivita" */ '@/views/NewAttivita.vue'),
    meta: {
      ruolo: ['Master', 'Tecnico', 'Partner']
    }
  },
  {
    path: '/schedaAttivita',
    name: 'schedaAttivita',
    component: () => import(/* webpackChunkName: "schedaAttivita" */ '@/views/SchedaAttivita.vue'),
    meta: {
      need: ['_id'],
    }
  },
  {
    path: '/stampaAttivita',
    name: 'stampaAttivita',
    component: () => import(/* webpackChunkName: "stampaAttivita" */ '@/views/StampaAttivita.vue'),
    meta: {
      need: ['_id'],
    }
  },
  {
    path: '/listDocumenti',
    name: 'listDocumenti',
    component: () => import(/* webpackChunkName: "listDocumenti" */ '@/views/ListDocumenti.vue'),
    meta: {
      ruolo: ['Master', 'Partner', 'Cliente']
    }
  },
  {
    path: '/newDocumento',
    name: 'newDocumento',
    component: () => import(/* webpackChunkName: "newDocumento" */ '@/views/NewDocumento.vue'),
    meta: {
      ruolo: ['Master', 'Partner', 'Cliente']
    }
  },
  {
    path: '/schedaDocumento',
    name: 'schedaDocumento',
    component: () => import(/* webpackChunkName: "schedaDocumento" */ '@/views/SchedaDocumento.vue'),
    meta: {
      need: ['_id'],
      ruolo: ['Master', 'Partner', 'Cliente']
    }
  },
  {
    path: '/schedaStabilimento',
    name: 'schedaStabilimento',
    component: () => import(/* webpackChunkName: "schedaStabilimento" */ '@/views/SchedaStabilimento.vue'),
    meta: {
      need: ['_id'],
      ruolo: ['Master', 'Tecnico', 'Partner', 'Cliente']
    }
  },
  {
    path: '/infoStabilimento',
    name: 'infoStabilimento',
    component: () => import(/* webpackChunkName: "infoStabilimento" */ '@/views/MappaStabilimento.vue'),
    meta: {
      need: ['_id'],
      //ruolo: ['Master', 'Tecnico', 'Partner', 'Cliente']
    }
  },
  {
    path: '/newEvacuatore',
    name: 'newEvacuatore',
    component: () => import(/* webpackChunkName: "newEvacuatore" */ '@/views/NewEvacuatore.vue'),
    meta: {
      ruolo: ['Master', 'Tecnico', 'Partner']
    }
  },
  {
    path: '/anteprimaDDT',
    name: 'anteprimaDDT',
    component: () => import(/* webpackChunkName: "anteprimaDDT" */ '@/views/AnteprimaDDT.vue'),
    meta: {
      need: ['_id'],
      ruolo: ['Master', 'Tecnico', 'Partner', 'Cliente']
    }
  },
  {
    path: '/listSegnalazioni',
    name: 'listSegnalazioni',
    component: () => import(/* webpackChunkName: "listSegnalazioni" */ '@/views/ListSegnalazioni.vue'),
    meta: {
      ruolo: ['Master', 'Tecnico', 'Partner', 'Cliente']
    }
  },
  {
    path: '/newSegnalazione',
    name: 'newSegnalazione',
    component: () => import(/* webpackChunkName: "newSegnalazione" */ '@/views/NewSegnalazione.vue'),
    meta: {
      ruolo: ['Master', 'Tecnico', 'Partner', 'Cliente']
    }
  },
  {
    path: '/schedaSegnalazione',
    name: 'schedaSegnalazione',
    component: () => import(/* webpackChunkName: "schedaSegnalazione" */ '@/views/SchedaSegnalazione.vue'),
    meta: {
      need: ['_id'],
      ruolo: ['Master', 'Tecnico', 'Partner', 'Cliente']
    }
  },

  // ruolo: CLIENTE
  {
    path: '/laMiaScheda',
    name: 'laMiaScheda',
    component: () => import(/* webpackChunkName: "laMiaScheda" */ '@/views/LaMiaScheda.vue'),
    meta: {
      ruolo: ['Cliente']
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

let ruolo, token;

router.beforeEach( async (to, from, next) => {
  if(
    to.query && 
    to.query.qr
  ){
    let data = await generalFunction.getIdStabilimentoFromQR(to.query.qr);
    if(
      data &&
      data['id_stabilimento'] &&
      data['id']
    ) next('/infoStabilimento?_id='+data['id_stabilimento']+'&id_e='+data['id']);
  } 

  if(from.name == 'Login'){
    token = await generalFunction.getToken();
    ruolo = await generalFunction.getRuolo(token);
  }
  if(!token) token = await generalFunction.getToken();
  if(!ruolo) ruolo = await generalFunction.getRuolo(token);
  
  let ok = true;
  if(to.meta.need && to.meta.need.length) {
    ok = false;
    for (const paramNeeded of to.meta.need) 
      if(to.query && to.query[paramNeeded]) ok = true;
  } 
  if(
    to.meta.ruolo && 
    to.meta.ruolo.length &&
    to.meta.ruolo.indexOf(ruolo) === -1
  ) ok = false;

  if(ok) next();
  else if(from.name) next(from.name);
  else next('/');
})

export default router;
