import Vue from 'vue';
import Vuex from 'vuex';
import { generalFunction } from './js/generalFunction.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        token: '',
        ruolo: ''
    },
    mutations: {
    },
    actions: {
    },
    getters : {
    }
});